import React, { memo, useMemo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import HomePage from './pages/homepage/HomePage';
import AboutPage from './pages/aboutpage/AboutPage';
import ServicesPage from './pages/servicespage/ServicesPage';
import PortfolioPage from './pages/portfoliopage/PortfolioPage';
import ContactPage from './pages/contactpage/ContactPage';
import NotFoundPage from './pages/NotFoundPage';
import ArtisticProjectsPage from './pages/artisticprojectspage/ArtisticProjectsPage';
import ScrollToTop from './utils/scrolltotop';

const App = () => {
  const routes = useMemo(() => (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/services" element={<ServicesPage />} />
      <Route path="/portfolio" element={<PortfolioPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/artistic-projects" element={<ArtisticProjectsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  ), []);

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Navbar />
        {routes}
        <Footer />
      </div>
    </Router>
  );
}

export default memo(App);
