import React from 'react';
import { Container, Typography, Grid, Paper, Avatar, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './_aboutpage.scss';
import NatashaAvatar from '../../assets/images/Nat_MUIavatar.jpg';
import MikeAvatar from '../../assets/images/Mike_MUIavatar.jpg';
import JoshAvatar from '../../assets/images/Josh_MUIavatar.jpg';
import MikeVideo from '../../assets/videos/MikeAboutMe.mp4'; // Example video file
import NatashaVideo from '../../assets/videos/NatashaAboutMe.mp4'; // Example video file
import JoshVideo from '../../assets/videos/JoshAboutMe.mp4'; // Example video file

const teamMembers = [
  {
    name: 'Michael McEvoy',
    role: 'Data Optimization Specialist',
    description: 'Michael specializes in email marketing, web/shopify design, data analytics, and SEO. His expertise ensures that your online presence is optimized for maximum visibility and impact. He is also pursuing his CPA to offer financial services soon.',
    imgSrc: MikeAvatar,
    videoSrc: MikeVideo,
  },
  {
    name: 'Natasha Malone',
    role: 'Founder & Social Media Specialist',
    description: 'Natasha spearheads all posts, account management, Shopify maintenance, and ad campaigns. With 3 years of experience, she excels in driving social media engagement and brand presence.',
    imgSrc: NatashaAvatar,
    videoSrc: NatashaVideo,
  },
  {
    name: 'Josh Rubin',
    role: 'Client Success Partner',
    description: 'Josh is dedicated to client relationships, ensuring that their needs are met and expectations exceeded. He focuses on understanding client goals and aligning them with our services for optimal outcomes.',
    imgSrc: JoshAvatar,
    videoSrc: JoshVideo,
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 1, // Increased stagger duration for a slower effect
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0, transition: { duration: 3 } }, // Increased duration for a slower effect
};

const videoVariants = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1, transition: { duration: 3 } }, // Increased duration for a slower effect
};

function AboutPage() {
  const [heroRef, heroInView] = useInView({ triggerOnce: true, threshold: 0.25 });
  const [fullWidthVideoRef, fullWidthVideoInView] = useInView({ triggerOnce: true, threshold: 0.25 });
  const [teamSectionRef, teamSectionInView] = useInView({ triggerOnce: true, threshold: 0.25 });

  return (
    <div className="AboutPage">
      <Box className="hero">
        <Container className="heroContent" ref={heroRef}>
          <motion.div
            initial="hidden"
            animate={heroInView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <Typography variant="h2">About Us</Typography>
            <Typography variant="h5">Meet the team behind NatXSocial</Typography>
          </motion.div>

          <Typography variant="h4" gutterBottom>Our Mission</Typography>
            <Typography variant="body1">
            At NatXSocial, we pride ourselves on being a grassroots digital marketing agency dedicated to helping businesses thrive online. In an industry often plagued by overinflated promises and generic solutions, our mission is to build a supportive community that empowers our clients through tailored, top-notch services. Whether you're a small startup or an established business, we are passionate about leveraging our expertise and creativity to elevate your digital presence and drive real results.
            </Typography>
        </Container>
      </Box>

      <Box className="root" ref={teamSectionRef}>
        <Container>
          <motion.div
            initial="hidden"
            animate={teamSectionInView ? "visible" : "hidden"}
            variants={containerVariants}
            className="teamSection"
          >
            {teamMembers.map((member, index) => (
              <Grid container spacing={4} alignItems="center" justifyContent="center" key={index}>
                {index % 2 === 0 ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={itemVariants}
                      >
                        <Paper className="teamCard">
                          <Avatar src={member.imgSrc} alt={member.name} className="teamAvatar" />
                          <Typography className="teamName">{member.name}</Typography>
                          <Typography className="teamRole">{member.role}</Typography>
                          <Typography className="teamDescription">{member.description}</Typography>
                        </Paper>
                      </motion.div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={videoVariants}
                      >
                        <Box className="videoContainer">
                          <video
                            className="teamVideo"
                            src={member.videoSrc}
                            muted
                            loop
                            controls
                            playsInline
                          />
                        </Box>
                      </motion.div>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} md={6}>
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={videoVariants}
                      >
                        <Box className="videoContainer">
                          <video
                            className="teamVideo"
                            src={member.videoSrc}
                            muted
                            loop
                            controls
                            playsInline
                          />
                        </Box>
                      </motion.div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={itemVariants}
                      >
                        <Paper className="teamCard">
                          <Avatar src={member.imgSrc} alt={member.name} className="teamAvatar" />
                          <Typography className="teamName">{member.name}</Typography>
                          <Typography className="teamRole">{member.role}</Typography>
                          <Typography className="teamDescription">{member.description}</Typography>
                        </Paper>
                      </motion.div>
                    </Grid>
                  </>
                )}
              </Grid>
            ))}
          </motion.div>
        </Container>
      </Box>
    </div>
  );
}

export default AboutPage;
