import React from 'react';
import { Container, Typography, Grid, Box, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import './_portfolioPage.scss';
import GoodNightJohnnys from '../../assets/images/GoodNightJohnnysLogo.PNG';
import HopkintonSkinCare from '../../assets/images/HopkintonSkinCare.PNG';
import SunshineVisionTherapy from '../../assets/images/SunshineVision.PNG';
import WorcesterYouthOrchestra from '../../assets/images/WYO.png';
import NatesNattySoap from '../../assets/images/NatesNatty.png';
import TheCoreConnection from '../../assets/images/CoreConnection.png';
import NatXLogo from '../../assets/images/NatXSocial.PNG';

const portfolioItems = [
  {
    title: "Goodnight Johnny's",
    image: GoodNightJohnnys,
    facebook: 'https://www.facebook.com/GoodnightJohnnys',
    instagram: 'https://www.instagram.com/goodnight_johnnys',
  },
  {
    title: 'Hopkinton SkinCare',
    image: HopkintonSkinCare,
    facebook: 'https://m.facebook.com/HopkintonSkinCarebyKathyThomas',
    instagram: 'https://www.instagram.com/hopkinton_skincare/',
  },
  {
    title: 'Sunshine Vision Therapy',
    image: SunshineVisionTherapy,
    facebook: 'https://www.facebook.com/sunshinevisiondevelopmentcenter',
    instagram: 'https://www.instagram.com/sun_shinevision',
  },
  {
    title: 'Worcester Youth Orchestra',
    image: WorcesterYouthOrchestra,
    facebook: 'https://www.facebook.com/WorcesterYouthOrchestras',
    instagram: 'https://www.instagram.com/wyorchestras',
  },
  {
    title: "Nate's Natty Soap",
    image: NatesNattySoap,
    instagram: 'https://www.instagram.com/nates.natty',
  },
  {
    title: 'The Core Connection',
    image: TheCoreConnection,
    facebook: 'https://www.facebook.com/thecoreconnectionstudio',
    instagram: 'https://www.instagram.com/thecoreconnection',
  },
];

const SectionTitle = styled(Typography)`
  text-align: center;
  margin: 3rem 0 1.5rem;
  color: #6f148d;
  font-size: 2rem;
  font-weight: 700;
`;

const ProjectDescription = styled(Typography)`
  text-align: center;
  margin-bottom: 2rem;
  color: #6f148d;
  padding: 0 2rem;
`;

function PortfolioPage() {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="PortfolioPage">
      <Container>
        <Typography variant="h3" className="header">Our Portfolio</Typography>
        
        <SectionTitle variant="h4">Client Content Portfolio</SectionTitle>
        <Grid container spacing={4} justifyContent="center" style={{ marginTop: '40px' }}>
          {portfolioItems.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <motion.div whileHover={{ scale: 1.05 }} className="portfolioItem">
                <img src={item.image} alt={item.title} className="portfolioImage" />
                <Typography variant="h5" className="portfolioTitle">{item.title}</Typography>
                <Box className="iconContainer">
                  {item.facebook && (
                    <IconButton href={item.facebook} target="_blank" className="icon">
                      <FacebookIcon />
                    </IconButton>
                  )}
                  <IconButton href={item.instagram} target="_blank" className="icon">
                    <InstagramIcon />
                  </IconButton>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>

        <SectionTitle variant="h4">Personal Projects</SectionTitle>
        <Grid container spacing={4} justifyContent="center" style={{ marginTop: '40px' }}>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div whileHover={{ scale: 1.05 }} className="portfolioItem">
              <a href="https://www.instagram.com/natxsocial" target="_blank" rel="noopener noreferrer">
                <img src={NatXLogo} alt="Personal Project 1" className="portfolioImage" />
                <Typography variant="h5" className="portfolioTitle">NatXSocial Instagram</Typography>
                <ProjectDescription>
                  Our Official Company Instagram
                </ProjectDescription>
              </a>
            </motion.div>
          </Grid>

        </Grid>
      </Container>
    </motion.div>
  );
}

export default PortfolioPage;
