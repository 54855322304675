import React from 'react';
import HomePageVideoNatX from '../../assets/videos/HomePageVideoNatX.mp4';
import { Container, Typography, Grid, Paper, Box, Button, Avatar } from '@mui/material';
import { SpeedDial, SpeedDialAction, Fab } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import InstagramIcon from '@mui/icons-material/Instagram';
import ReelsIcon from '@mui/icons-material/Movie';
import SMSIcon from '@mui/icons-material/Sms';
import PhotographyIcon from '@mui/icons-material/CameraAlt';
import ComputerIcon from '@mui/icons-material/Computer';
import AnalyticsIcon from '@mui/icons-material/BarChart';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import SEOIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import ShopifyIcon from '@mui/icons-material/Shop';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './_homePage.scss';
import MikeAvatar from '../../assets/images/Mike_MUIavatar.jpg';
import NatashaAvatar from '../../assets/images/Nat_MUIavatar.jpg';
import JoshAvatar from '../../assets/images/Josh_MUIavatar.jpg';
import MainstreamBoutique from '../../assets/images/MainstreamBoutique.PNG';
import GoodNightJohnnys from '../../assets/images/GoodNightJohnnysLogo.PNG';
import HopkintonSkinCare from '../../assets/images/HopkintonSkinCare.PNG';


const services = [
  { icon: <InstagramIcon className="serviceIcon" />, title: 'Standard Posts', description: 'Standard posts on all platforms', link: '/services' },
  { icon: <ReelsIcon className="serviceIcon" />, title: 'Reels & Video Content', description: 'Engaging reels and video content', link: '/services' },
  { icon: <SMSIcon className="serviceIcon" />, title: 'SMS Marketing', description: 'Effective SMS marketing campaigns', link: '/services' },
  { icon: <EmailIcon className="serviceIcon" />, title: 'Email Marketing', description: 'Targeted email marketing', link: '/services' },
  { icon: <PhotographyIcon className="serviceIcon" />, title: 'Professional Photography', description: 'High-quality professional photography', link: '/services' },
  { icon: <ComputerIcon className="serviceIcon" />, title: 'Web Design', description: 'Creative and responsive web design', link: '/services' },
  { icon: <AnalyticsIcon className="serviceIcon" />, title: 'Data Analytics', description: 'In-depth data analytics', link: '/services' },
  { icon: <ShopifyIcon className="serviceIcon" />, title: 'Shopify Maintenance', description: 'Shopify maintenance and design', link: '/services' },
  { icon: <><GoogleIcon className="serviceIcon" /><FacebookIcon className="serviceIcon" /></>, title: 'Google & Facebook Ads', description: 'Effective ad campaigns on Google and Facebook', link: '/services' },
  { icon: <SEOIcon className="serviceIcon" />, title: 'SEO', description: 'Search engine optimization', link: '/services' },
];

const stats = [
  { number: '10+', label: 'Happy Clients' },
  { number: '12+', label: 'Services Offered' },
  { number: '100%+', label: 'Average Follower Growth' },
  { number: '3+', label: 'Years in Business' },
];

const teamMembers = [
  { name: 'Michael McEvoy', role: 'Data Optimization Specialist', imgSrc: MikeAvatar, link: '/about' },
  { name: 'Natasha Malone', role: 'Founder', imgSrc: NatashaAvatar, link: '/about' },
  { name: 'Josh Rubin', role: 'Client Success Partner', imgSrc: JoshAvatar, link: '/about' },
];

const projects = [
  { imgSrc: MainstreamBoutique, title: 'Mainstream Boutique', link: '/portfolio' },
  { imgSrc: GoodNightJohnnys, title: 'Good Night Johnny\'s', link: '/portfolio' },
  { imgSrc: HopkintonSkinCare, title: 'Hopkinton Skin Care', link: '/portfolio' },
];

const AnimatedServiceCard = ({ service, index }) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const animationProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 1400, delay: index * 100 },
  });

  return (
    <Grid item xs={12} sm={6} md={4} ref={ref}>
      <animated.div style={animationProps}>
        <Link to={service.link} style={{ textDecoration: 'none' }}>
          <Paper className="serviceCard">
            {service.icon}
            <Typography variant="h6">{service.title}</Typography>
            <Typography>{service.description}</Typography>
          </Paper>
        </Link>
      </animated.div>
    </Grid>
  );
};

const AnimatedProjectCard = ({ project, index }) => {
  const { ref, inView } = useInView({ triggerOnce: true });
  const animationProps = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 1400, delay: index * 100 },
  });

  return (
    <Grid item xs={12} sm={6} md={4} ref={ref}>
      <animated.div style={animationProps}>
        <Box className={`projectCard project-${index}`}>
          <Link to={project.link}>
            <img src={project.imgSrc} alt={project.title} className={`projectImage projectImage-${index}`} />
          </Link>
        </Box>
      </animated.div>
    </Grid>
  );
};

const actions = [
  { icon: <EmailIcon />, name: 'Email', link: 'mailto:natasha.malone@natxsocial.com' },
  { icon: <PhoneIcon />, name: 'Call', link: 'tel:+1 508 210 6406' },
  { icon: <CalendarTodayIcon />, name: 'Meeting', link: 'https://calendly.com/natxsocial' },
];

function HomePage() {
  const heroRef = useInView({ triggerOnce: true });
  const statsRef = useInView({ triggerOnce: true });
  const teamRef = useInView({ triggerOnce: true });
  const projectsRef = useInView({ triggerOnce: true });
  const videoRef = useInView({ triggerOnce: true });

  const heroProps = useSpring({
    opacity: heroRef.inView ? 1 : 0,
    transform: heroRef.inView ? 'translateY(0)' : 'translateY(-50px)',
    config: { duration: 1400 },
  });

  const statsProps = useSpring({
    opacity: statsRef.inView ? 1 : 0,
    transform: statsRef.inView ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 1400 },
  });

  const teamProps = useSpring({
    opacity: teamRef.inView ? 1 : 0,
    transform: teamRef.inView ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 1400 },
  });

  const projectsProps = useSpring({
    opacity: projectsRef.inView ? 1 : 0,
    transform: projectsRef.inView ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 1400 },
  });

  const videoProps = useSpring({
    opacity: videoRef.inView ? 1 : 0,
    transform: videoRef.inView ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 1400 },
  });

  const handleActionClick = (link) => {
    window.location.href = link;
  };

  return (
    <div className="HomePage">
      <animated.div style={heroProps}>
        <Box className="hero" ref={heroRef.ref}>
        <video autoPlay muted loop style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
          <source src={HomePageVideoNatX} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

        </Box>
      </animated.div>  
      <Box className="servicesSection">
        <Container>
          <Typography variant="h4" gutterBottom align="center">Our Services</Typography>
          <Grid container spacing={4}>
            {services.map((service, index) => (
              <AnimatedServiceCard service={service} index={index} key={index} />
            ))}
          </Grid>
        </Container>
      </Box>

      <animated.div style={statsProps}>
        <Box className="statsSection" ref={statsRef.ref}>
          <Container>
            <Typography variant="h4" gutterBottom align="center">Our Achievements</Typography>
            <Grid container spacing={4}>
              {stats.map((stat, index) => (
                <Grid item xs={12} sm={6} md={3} key={index}>
                  <Paper className="statCard">
                    <Typography variant="h3">{stat.number}</Typography>
                    <Typography>{stat.label}</Typography>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </animated.div>

      <animated.div style={teamProps}>
        <Box className="teamSection" ref={teamRef.ref}>
          <Container>
            <Typography variant="h4" gutterBottom align="center">Meet Our Team</Typography>
            <Grid container spacing={4}>
              {teamMembers.map((member, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Link to={member.link} style={{ textDecoration: 'none' }}>
                    <Paper className="teamCard">
                      <Avatar src={member.imgSrc} alt={member.name} sx={{ width: 80, height: 80, margin: 'auto' }} />
                      <Typography variant="h6">{member.name}</Typography>
                      <Typography>{member.role}</Typography>
                    </Paper>
                  </Link>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>
      </animated.div>

      <animated.div style={projectsProps}>
        <Box className="projectSection" ref={projectsRef.ref}>
          <Container>
            <Typography variant="h4" gutterBottom align="center">Our Clients</Typography>
            <Grid container spacing={4}>
              {projects.map((project, index) => (
                <AnimatedProjectCard project={project} index={index} key={index} />
              ))}
            </Grid>
          </Container>
        </Box>
      </animated.div>

      
      <SpeedDial
        ariaLabel="Schedule a Consultation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        icon={
          <Fab 
            variant="extended" 
            sx={{ 
              backgroundColor: '#6f148d', 
              color: '#ffffff', 
              width: '250px', 
              height: '56px', 
              borderRadius: '28px', 
              fontWeight: 'bold' // Make text bold
            }}
          >
            SCHEDULE NOW
          </Fab>
        }
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            sx={{ 
              color: '#6f148d', 
              '& .MuiSpeedDialAction-staticTooltipLabel': { 
                backgroundColor: '#f3f1e7', 
                color: '#6f148d' 
              },
              '& .MuiSpeedDialAction-fab': {
                backgroundColor: '#6f148d', 
                color: '#ffffff',
              },
              display: 'flex',
              justifyContent: 'center', // Center icon within button
              alignItems: 'center', // Center icon vertically
              cursor: 'pointer'
            }}
            onClick={() => handleActionClick(action.link)}
          />
        ))}
      </SpeedDial>
    </div>
  );
}

export default HomePage;
