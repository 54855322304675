import React from 'react';
import { Container, Typography} from '@mui/material';
import { motion } from 'framer-motion';
import Carousel from 'react-material-ui-carousel';
import styled from '@emotion/styled';
import images from '../../utils/importImages';
import './_artisticProjectsPage.scss';

const SectionTitle = styled(Typography)`
  text-align: center;
  margin: 3rem 0 1.5rem;
  color: #6f148d;
  font-size: 2rem;
  font-weight: 700;
`;

const carouselItems = [
  {
    category: 'Best Photos',
    items: ['Photo1.jpg', 'Photo2.jpg', 'Photo3.jpg'],
  },
  {
    category: 'Best Videos',
    items: ['Video1.mp4', 'Video2.mp4', 'Video3.mp4'],
  },
  {
    category: 'Pets',
    items: ['Pet1.jpg', 'Pet2.jpg', 'Pet3.jpg'],
  },
  {
    category: 'Travel/Nature',
    items: ['Travel1.jpg', 'Travel2.jpg', 'Travel3.jpg'],
  },
];

const containerVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.5, delay: 0.2 },
  },
};

const ArtisticProjectsPage = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="ArtisticProjectsPage">
      <Container>
        <Typography variant="h3" className="header">Artistic Projects</Typography>
        
        {carouselItems.map((carousel, index) => (
          <motion.div
            key={index}
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            className="carouselSection"
          >
            <SectionTitle variant="h4">{carousel.category}</SectionTitle>
            <Carousel>
              {carousel.items.map((item, idx) => (
                <motion.div whileHover={{ scale: 1.05 }} className="carouselItem" key={idx}>
                  <img src={images[item].default} alt={`${carousel.category} ${idx + 1}`} className="carouselImage" />
                </motion.div>
              ))}
            </Carousel>
          </motion.div>
        ))}
      </Container>
    </motion.div>
  );
};

export default ArtisticProjectsPage;
