import React from 'react';
import { Container, Typography, Grid, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import EventIcon from '@mui/icons-material/Event';
import InstagramIcon from '@mui/icons-material/Instagram';
import { styled } from '@mui/material/styles';
import './_contactPage.scss';

const PREFIX = 'ContactPage';

const classes = {
  root: `${PREFIX}`,
  content: `${PREFIX}-content`,
  contactDetails: `${PREFIX}-contactDetails`,
  icon: `${PREFIX}-icon`,
  contactItem: `${PREFIX}-contactItem`,
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    backgroundColor: '#f3f1e7',
  },
  [`& .${classes.content}`]: {
    paddingTop: '12rem',
    paddingBottom: '8rem',
  },
  [`& .${classes.contactDetails}`]: {},
  [`& .${classes.icon}`]: {
    color: '#6f148d',
  },
  [`& .${classes.contactItem}`]: {
    textAlign: 'center',
    '&:hover .MuiTypography-root': {
      color: '#5c0d6d',
    },
  },
});

function ContactPage() {
  return (
    <Root className={classes.root}>
      <Container className={classes.content}>
        <Typography variant="h3" align="center" gutterBottom style={{ color: '#6f148d' }}>
          Contact Us
        </Typography>
        <Typography variant="body1" align="center" gutterBottom style={{ color: '#6f148d' }}>
          We're here to help! Click below to reach us.
        </Typography>
        <Grid container spacing={4} className={classes.contactDetails} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={6} md={3} className={classes.contactItem}>
            <IconButton href="tel:5082106406">
              <PhoneIcon className={classes.icon} />
            </IconButton>
            <Typography variant="h6">Phone</Typography>
            <Typography>(508) 210-6406</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.contactItem}>
            <IconButton href="mailto:natasha.malone@natxsocial.com">
              <EmailIcon className={classes.icon} />
            </IconButton>
            <Typography variant="h6">Email</Typography>
            <Typography>natasha.malone@natxsocial.com</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.contactItem}>
            <IconButton href="https://calendly.com/natxsocial" target="_blank">
              <EventIcon className={classes.icon} />
            </IconButton>
            <Typography variant="h6">Schedule</Typography>
            <Typography>Calendly</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} className={classes.contactItem}>
            <IconButton href="https://www.instagram.com/natxsocial/" target="_blank">
              <InstagramIcon className={classes.icon} />
            </IconButton>
            <Typography variant="h6">Instagram</Typography>
            <Typography>@natxsocial</Typography>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
}

export default ContactPage;
