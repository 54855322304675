import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import EmailIcon from '@mui/icons-material/Email';
import Logo from '../../assets/images/NatxSocialLogo.png';
import './_navbar.scss'

const PREFIX = 'Navbar';

const classes = {
  title: `${PREFIX}-title`,
  navLinks: `${PREFIX}-navLinks`,
  link: `${PREFIX}-link`,
  toolbar: `${PREFIX}-toolbar`,
  menuButton: `${PREFIX}-menuButton`,
  logo: `${PREFIX}-logo`,
  menu: `${PREFIX}-menu`,
  menuItem: `${PREFIX}-menuItem`,
  appBar: `${PREFIX}-appBar`,
  emailIcon: `${PREFIX}-emailIcon`,
};

const Root = styled('div')(() => ({
  button: {
    backgroundColor: 'transparent',
    color: 'inherit',
    border: 'none',
    padding: '8px 16px',
    fontSize: '1rem',
    cursor: 'pointer',
    '&:hover': {
      color: '#d1c4e9',
    },
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({ target: window ? window() : undefined });
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [scrolled, setScrolled] = useState(false);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Root>
      <HideOnScroll>
        <AppBar
          position="fixed"
          className={classes.appBar}
          style={{
            backgroundColor: scrolled ? '#6f148d' : 'transparent',
            color: scrolled ? '#f3f1e7' : '#f3f1e7',
            boxShadow: scrolled ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
          }}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              className={classes.menuButton}
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                className: classes.menu,
              }}
            >
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/"
                className={classes.menuItem}
              >
                Home
              </MenuItem>
              
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/services"
                className={classes.menuItem}
              >
                Services
              </MenuItem>
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/about"
                className={classes.menuItem}
              >
                About Us
              </MenuItem>
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/contact"
                className={classes.menuItem}
              >
                Contact Us
              </MenuItem>
              <MenuItem
                onClick={handleMenuClose}
                component={Link}
                to="/portfolio"
                className={classes.menuItem}
              >
                Portfolio
              </MenuItem>
            </Menu>
            <Link to="/">
              <img src={Logo} alt="Logo" className={classes.logo} />
            </Link>
            <div className={classes.navLinks}>
              <Link to="/services" className={classes.link}>
                <Button className={classes.button}>Services</Button>
              </Link>
              <Link to="/about" className={classes.link}>
                <Button className={classes.button}>About Us</Button>
              </Link>
              <Link to="/portfolio" className={classes.link}>
                <Button className={classes.button}>Portfolio</Button>
              </Link>
              <Link to="/contact" className={classes.link}>
                <Button className={classes.button}>Contact</Button>
              </Link>
            </div>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="email"
              className={classes.emailIcon}
              href="mailto:natasha.malone@natxsocial.com"
            >
              <EmailIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </Root>
  );
}

export default Navbar;
