// src/pages/ServicesPage.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Grid, Box, Card, CardContent, CardActions, Button, Divider, Rating } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import ReelsIcon from '@mui/icons-material/Movie';
import SMSIcon from '@mui/icons-material/Sms';
import EmailIcon from '@mui/icons-material/Email';
import PhotographyIcon from '@mui/icons-material/CameraAlt';
import ComputerIcon from '@mui/icons-material/Computer';
import AnalyticsIcon from '@mui/icons-material/BarChart';
import ShoppingCartIcon from '@mui/icons-material/Shop';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import SEOIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import TeamworkIcon from '@mui/icons-material/GroupWork';
import GrassrootsIcon from '@mui/icons-material/Grass';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';
import './_servicesPage.scss';

const services = [
  { icon: <InstagramIcon className="serviceIcon" />, title: 'Standard Posts', description: 'Standard posts on all platforms' },
  { icon: <ReelsIcon className="serviceIcon" />, title: 'Reels & Video Content', description: 'Engaging reels and video content',},
  { icon: <SMSIcon className="serviceIcon" />, title: 'SMS Marketing', description: 'Effective SMS marketing campaigns',  },
  { icon: <EmailIcon className="serviceIcon" />, title: 'Email Marketing', description: 'Targeted email marketing',  },
  { icon: <PhotographyIcon className="serviceIcon" />, title: 'Professional Photography', description: 'High-quality professional photography', },
  { icon: <ComputerIcon className="serviceIcon" />, title: 'Web Design', description: 'Creative and responsive web design', },
  { icon: <AnalyticsIcon className="serviceIcon" />, title: 'Data Analytics', description: 'In-depth data analytics',  },
  { icon: <ShoppingCartIcon className="serviceIcon" />, title: 'Shopify Maintenance', description: 'Shopify maintenance and design', },
  { icon: <><GoogleIcon className="serviceIcon" /><FacebookIcon className="serviceIcon" /></>, title: 'Google & Facebook Ads', description: 'Effective ad campaigns on Google and Facebook',},
  { icon: <SEOIcon className="serviceIcon" />, title: 'SEO', description: 'Search engine optimization', },
];

function ServicesPage() {
  const [heroRef, heroInView] = useInView({ triggerOnce: true, threshold: 0.5 });
  const [valuesRef, valuesInView] = useInView({ triggerOnce: true, threshold: 0.5 });

  const heroProps = useSpring({
    opacity: heroInView ? 1 : 0,
    transform: heroInView ? 'translateY(0)' : 'translateY(-50px)',
    config: { duration: 1400 },
  });

  const valuesProps = useSpring({
    opacity: valuesInView ? 1 : 0,
    transform: valuesInView ? 'translateY(0)' : 'translateY(50px)',
    config: { duration: 1400 },
  });

  return (
    <div className="ServicesPage">
      <animated.div style={heroProps} ref={heroRef}>
        <Box className="hero">
          <Container className="heroContent">
            <Typography variant="h2">Our Services</Typography>
            <Typography variant="h5">Discover our range of services designed to help your business grow.</Typography>
          </Container>
        </Box>
      </animated.div>

      <animated.div style={valuesProps} ref={valuesRef}>
        <Box className="valuesSection">
          <Container>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <Card className="valuesCard">
                  <StarIcon className="serviceIcon" />
                  <Typography variant="h6" className="cardTitle">
                    Collaborative Approach
                  </Typography>
                  <Typography className="cardContent">
                    We work closely with our clients to understand their goals and create customized strategies that deliver results.
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card className="valuesCard">
                  <StarIcon className="serviceIcon" />
                  <Typography variant="h6" className="cardTitle">
                    Grassroots Origins
                  </Typography>
                  <Typography className="cardContent">
                    Our grassroots beginnings keep us grounded and focused on providing personal and attentive service to every client.
                  </Typography>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Card className="valuesCard">
                  <StarIcon className="serviceIcon" />
                  <Typography variant="h6" className="cardTitle">
                    Proven Results
                  </Typography>
                  <Typography className="cardContent">
                    Our track record of success speaks for itself. We have helped numerous clients achieve their digital marketing growth targets.
                  </Typography>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </animated.div>

      <Box className="serviceSection">
        <Container>
          <Typography variant="h4" gutterBottom align="center">Our Services</Typography>
          <Grid container spacing={4}>
            {services.map((service, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card className="serviceCard">
                  <CardContent>
                    {service.icon}
                    <Typography variant="h6" className="cardTitle">
                      {service.title}
                    </Typography>
                    <Typography className="cardContent">{service.description}</Typography>
                    <Divider />
                    <Rating name="service-rating" value={5} readOnly icon={<StarIcon fontSize="inherit" />} />
                  </CardContent>
                  <CardActions className="cardActions">
                    <Button component={Link} to="/contact" variant="contained" color="secondary">Learn More</Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default ServicesPage;
