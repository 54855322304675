import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography, IconButton, Box } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import EventIcon from '@mui/icons-material/Event';
import '../footer/_footer.scss';

const PREFIX = 'Footer';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  link: `${PREFIX}-link`,
  icon: `${PREFIX}-icon`,
  footerText: `${PREFIX}-footerText`,
  socialMedia: `${PREFIX}-socialMedia`,
};

const Root = styled('footer')(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.container}`]: {},
  [`& .${classes.link}`]: {},
  [`& .${classes.icon}`]: {},
  [`& .${classes.footerText}`]: {},
  [`& .${classes.socialMedia}`]: {},
}));

function Footer() {
  return (
    <Root className={classes.root}>
      <Container className={classes.container}>
        <Box className={classes.socialMedia}>
          <IconButton className={classes.icon} href="https://www.instagram.com/natxsocial/" target="_blank">
            <InstagramIcon />
          </IconButton>
          <IconButton className={classes.icon} href="tel:5082106406">
            <PhoneIcon />
          </IconButton>
          <IconButton className={classes.icon} href="https://calendly.com/natxsocial" target="_blank">
            <EventIcon />
          </IconButton>
          <IconButton className={classes.icon} href="mailto:natasha.malone@natxsocial.com">
            <EmailIcon />
          </IconButton>
        </Box>
        <Typography variant="body2" className={classes.footerText}>
          © 2024 NatXSocial. All rights reserved.
        </Typography>
      </Container>
    </Root>
  );
}

export default Footer;
